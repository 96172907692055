<template>
    <main id="liste">
        <HeaderTab 
            :title="$t('marketplace.methode_paiements')" 
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Marketplace from '@/mixins/Marketplace.js'
    import Navigation from '@/mixins/Navigation.js'
    import Vue from 'vue'
    import Countries from 'GroomyRoot/assets/lang/countries/countries'

	export default {
        name: "PaymentsMethods",
        mixins:[Marketplace, Invoice, Navigation],
		data () {
			return {
                working: false,
                processing: false,
                require_infos: false,
                datas_required: [],
                payment_method: null,
                items_to_pay: null,
                form: {
                	birthday: new Date(),
                	country: 75,
                	ad1: null,
                	code_postal: null,
                	ville: null,
                	name: null,
                	firstname: null,
                },
                events_tab: {
                    "TableAction::addPaymentMethod": (params) => {
                        this.$router.push({ name: 'AddPaymentMethod' })
                    }
                }
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                this.working = true

                await this.checkUserInfos()

				this.working = false
            },

            async checkUserInfos() {
                this.checkRequiredUserInfos()
                .then(r => {
                    if(r.code_retour == "PM")
                    {
                    	//Affichage du formulaire pour completer les infos du user
                    	this.require_infos = true
                    	this.datas_required = r.message
                    }
                    // else
                    // {
                    //     this.load_to_pay()
                    // }
                    else
                    {
                        // this.$router.push({ name: 'paymentMethodList' })
                        // 
                    }
                })
                .catch(e => {

                })
            },

            async load_to_pay() {
                this.getAddressedInvoicesToPay()
                .then(r => {
                    this.items_to_pay = r
                })
                .catch(e => {
                })
            },

            async checkForm(){
                let values = {}

                Object.keys(this.datas_required).forEach((item) => {

                    if(this.datas_required[item] == "inputText")
                    {
                        if(this.form[item].val != null && this.form[item].val != "")
                        {
                            values[item] = this.form[item].val
                        }
                    }
                    else if(this.datas_required[item] == "date")
                    {
                        values[item] = this.form[item]
                    }
                    else if(this.datas_required[item] == "countryInput")
                    {
                        values[item] = Countries[Vue.i18n.locale()].iso_3[this.form[item]]
                    }
                })

                await this.saveRequiredUserInfos(values)
            }
            
        },
        
        computed: {
            
        },

		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            date : () => import('GroomyRoot/components/Utils/EDatepicker'),
			inputText : () => import('GroomyRoot/components/Utils/EInput'),
			CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
		}
	}
</script>
